import { useEffect, useState } from "react";
import { useAdminContext } from "../../contexts/AdminContext";

const ArticleDetails = () => {
  const { articles, selectedDetailsID } = useAdminContext();
  const [articleDetails, setArticleDetails] = useState(null);

  useEffect(() => {
    if (selectedDetailsID !== "new") {
      const foundArticle = articles.find(
        (item) => item.id === selectedDetailsID
      );
      if (foundArticle) {
        setArticleDetails({ ...foundArticle });
      }
    }
  }, [articles, selectedDetailsID]);

  return <>{articleDetails && <>{articleDetails.title}</>}</>;
};

export default ArticleDetails;
