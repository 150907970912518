import { useAppContext } from "../../contexts/AppContext";
import { motion } from "framer-motion";
import Loading from "../Loading";

const Patrons = () => {
  const { pageVariants, people } = useAppContext();

  const patronsVariants = {
    hidden: {},
    visible: { transition: { when: "beforeChildren", staggerChildren: 0.1 } },
  };
  const leftPatronVariants = {
    hidden: { opacity: 0, x: -400, y: -200 },
    visible: { opacity: 1, x: 0, y: 0 },
  };
  const rightPatronVariants = {
    hidden: { opacity: 0, x: 400, y: -200 },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <motion.div
      className="pageContainer"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {people.filter((item) => item.type === "patrons").length < 1 && (
        <div className="flex flex-wrap gap-[30px] justify-center mb-[30px]">
          <Loading />
        </div>
      )}
      {people.filter((item) => item.type === "patrons").length > 0 && (
        <motion.div
          className="pageSection flex flex-wrap justify-center mb-[30px] py-[30px]"
          variants={patronsVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="hidden break700:block flex-1 min-h-[400px] px-[30px]"
            variants={leftPatronVariants}
          >
            {people
              .filter(
                (item, index) => item.type === "patrons" && index % 2 === 0
              )
              .map((item) => (
                <div className="py-[15px] mb-[10px]" key={item.id}>
                  <h3 className="font-handwritten pb-[5px] text-[26px]">
                    {item.name}
                  </h3>
                  <span className="block font-handwritten opacity-60 pb-[20px] text-[18px]">
                    {item.position}
                  </span>
                  <div>
                    <p>{item.bio}</p>
                  </div>
                </div>
              ))}
          </motion.div>
          <span className="hidden break700:block basis-px bg-lightColor opacity-50"></span>
          <motion.div
            className="hidden break700:block flex-1 px-[30px]"
            variants={rightPatronVariants}
          >
            {people
              .filter(
                (item, index) => item.type === "patrons" && index % 2 !== 0
              )
              .map((item) => (
                <div className="py-[15px] mb-[10px]" key={item.id}>
                  <h3 className="font-handwritten pb-[5px] text-[26px]">
                    {item.name}
                  </h3>
                  <span className="block font-handwritten opacity-60 pb-[20px] text-[18px]">
                    {item.position}
                  </span>
                  <div>
                    <p>{item.bio}</p>
                  </div>
                </div>
              ))}
          </motion.div>
          <motion.div
            className="break700:hidden flex-1 px-[30px]"
            variants={rightPatronVariants}
          >
            {people
              .filter((item) => item.type === "patrons")
              .map((item) => (
                <div className="py-[15px] mb-[10px]" key={item.id}>
                  <h3 className="font-handwritten pb-[5px] text-[26px]">
                    {item.name}
                  </h3>
                  <span className="block font-handwritten opacity-60 pb-[20px] text-[18px]">
                    {item.position}
                  </span>
                  <div>
                    <p>{item.bio}</p>
                  </div>
                </div>
              ))}
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default Patrons;
