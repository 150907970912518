import { useAuth } from "./contexts/AuthContext";
import AdminContextProvider from "./contexts/AdminContext";
import Login from "./aComponents/Login";
import Dashboard from "./aComponents/Dashboard";

const Admin = () => {
  const { currentUser } = useAuth();

  return (
    <div className="admin no_select min-h-screen w-full flex flex-col justify-center items-center bg-slate-100">
      {!currentUser && <Login />}
      {currentUser && (
        <AdminContextProvider>
          <Dashboard />
        </AdminContextProvider>
      )}
    </div>
  );
};

export default Admin;
