import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="h-auto flex bg-[rgba(255,255,255,0.05)] px-[30px] items-center flex-row justify-end break480:justify-between">
      <span className="hidden break480:block opacity-30 text-center leading-[40px] text-[15px]">
        © Intent New Theatre 2021
      </span>
      <span className="hidden break770:block opacity-30 text-center leading-[40px] text-[15px]">
        Registered charity number: 1168015
      </span>
      <div className="flex justify-end items-center gap-5 opacity-30 h-[40px]">
        <a
          className="leading-[40px] text-[20px] text-lightColor"
          href="https://www.instagram.com/"
        >
          <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
        </a>
        <a
          className="leading-[40px] text-[20px] text-lightColor"
          href="https://twitter.com/intenttheatre"
        >
          <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
        </a>
        <a
          className="leading-[40px] text-[20px] text-lightColor"
          href="https://www.youtube.com/channel/UC0lYYvValSs50SC278IiYhA"
        >
          <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
        </a>
        <a
          className="leading-[40px] text-[20px] text-lightColor"
          href="https://www.facebook.com/intentnewtheatre/"
        >
          <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
        </a>
      </div>
    </div>
  );
};

export default Footer;
