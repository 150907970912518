import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const { logout } = useAuth();

  // Logout
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {}
  };

  return (
    <>
      <div className="w-full p-3 bg-white g-shadow flex justify-between items-center">
        <Link to="/">
          <h1 className="pl-3 text-xl uppercase">Intent New Theatre</h1>
        </Link>
        <span
          className="p-3 cursor-pointer h-shadow rounded-lg "
          onClick={handleLogout}
        >
          <FontAwesomeIcon className="text-black" icon={faRightFromBracket} />
        </span>
      </div>
    </>
  );
};

export default Header;
