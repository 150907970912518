import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../contexts/AppContext";

const Home = () => {
  const { pageVariants, openModal } = useAppContext();

  const homeImageVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { when: "beforeChildren", staggerChildren: 0.05 },
    },
  };
  const slatVariants = {
    hidden: { x: 400, y: -200, opacity: 0 },
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "spring", mass: 0.4, damping: 8 },
    },
  };
  const tagContVariants = {
    hidden: { opacity: 0, x: -400, y: -200 },
    visible: { opacity: 1, x: 0, y: 0 },
  };
  const announcementContVariants = {
    hidden: { opacity: 0, x: -400, y: -200 },
    visible: { opacity: 1, x: 0, y: 0 },
    hover: { scale: 1.1, originX: 0, textShadow: "0 0 8px rgba(0,0,0,0.2)" },
  };
  const announcementIconVariants = {
    hidden: { scale: 1 },
    visible: { scale: 1 },
    hover: { scale: 2 },
  };

  const handleAnnouncementClick = () => openModal("announcements");

  return (
    <motion.div
      className="max-w-[1440px] mx-auto w-full overflow-x-hidden min-h-[calc(100vh-100px)] flex justify-evenly flex-col break870:min-h-[calc(100vh-140px)] break870:flex-row-reverse break870:justify-center break870:items-center break870:p-[30px]"
      variants={pageVariants}
      exit="exit"
    >
      <motion.div
        className="h-[300px] w-full px-[30px] mx-auto flex justify-center gap-[5px] break870:gap-[10px] break560:min-h-[calc(100vh-660px)] tall:break560:min-h-[489px] break870:h-[489px] break870:min-h-[0] break870:w-auto break870:pr-0 break870:flex-1 break1200:mx-0 break1200:grow-0"
        variants={homeImageVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="slat slat1" variants={slatVariants}></motion.div>
        <motion.div className="slat slat2" variants={slatVariants}></motion.div>
        <motion.div className="slat slat3" variants={slatVariants}></motion.div>
        <motion.div className="slat slat4" variants={slatVariants}></motion.div>
      </motion.div>
      <motion.div
        className="px-[30px] pb-[30px] break870:pr-[30px] break870:pl-[0px] break870:pb-[0px] break870:basis-[495px]"
        variants={tagContVariants}
        initial="hidden"
        animate="visible"
      >
        <h3 className="relative leading-loose mt-[40px] mb-[20px]">
          <span className="block !font-handwritten text-[16px] break360:text-[20px] break440:text-[26px] break510:text-[30px] break582:text-[36px] break870:text-[32px]">
            Raising the curtain on
          </span>
          <span className="block !font-handwritten text-[16px] break360:text-[20px] break440:text-[26px] break510:text-[30px] break582:text-[36px] break870:text-[32px]">
            Contemporary theatre
          </span>
          <span className="block !font-handwritten text-[16px] break360:text-[20px] break440:text-[26px] break510:text-[30px] break582:text-[36px] break870:text-[32px]">
            In the Balkans
          </span>
          <span className="absolute top-0 left-0 -z-10 bold opacity-5 -translate-y-[55%] font-extrabold tracking-[16px] text-[50px] break360:text-[65px] break440:tracking-[32px] break510:text-[85px]">
            INTENT
          </span>
        </h3>
        <motion.button
          className="relative inline-block h-[60px] pl-[70px] break360:h-[70px] break360:pl-[80px] break440:h-[90px] break440:pl-[100px]"
          onClick={handleAnnouncementClick}
          variants={announcementContVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
        >
          <span className="absolute block h-[60px] w-[60px] top-0 left-0 bg-quoteRed rounded-full break360:h-[70px] break360:w-[70px] break440:h-[90px] break440:w-[90px]"></span>
          <motion.span
            className="absolute left-[11px] top-[11px] block h-[38px] w-[38px] bg-backgroundGrey rounded-full break360:left-[15px] break360:top-[15px] break360:h-[40px] break360:w-[40px] break440:left-[23px] break440:top-[23px] break440:h-[44px] break440:w-[44px]"
            variants={announcementIconVariants}
          >
            <FontAwesomeIcon
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[18px]"
              icon={faNewspaper}
            />
          </motion.span>
          <span className="uppercase font-extrabold tracking-widest text-[14px] break360:text-[16px] break440:text-[18px]">
            Announcements
          </span>
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default Home;
