import { motion, AnimatePresence } from "framer-motion";
import { useAppContext } from "../contexts/AppContext";

const Modal = () => {
  const modalContVariants = {
    hidden: { scale: 0, rotate: -10 },
    visible: {
      scale: 1,
      rotate: 0,
      transition: { when: "beforeChildren", staggerChildren: 0.1 },
    },
  };

  const backdropVariants = {
    hidden: { opacity: 1 },
    visible: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };

  const closeVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
    hover: {},
  };

  const closeInner = {
    hover: { rotate: 90 },
  };

  const { modal, closeModal } = useAppContext();

  const handleModalExit = () => closeModal(null);
  const handlePropagation = (e) => e.stopPropagation();

  return (
    <AnimatePresence exitBeforeEnter>
      {modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="fixed inset-0 h-full w-full z-40 flex items-center justify-center bg-[rgba(0,0,0,0.7)] backdrop-blur-sm"
          key="modal"
          onClick={handleModalExit}
        >
          <motion.div
            className="relative bg-backgroundGrey rounded-lg max-w-[600px] max-h-[600px] overflow-x-hidden overflow-y-auto h-[80%] w-[90%] shadow-modal"
            onClick={handlePropagation}
            variants={modalContVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="bg-[rgba(255,255,255,0.05)] pt-[44px] px-[30px] pb-[30px] h-full w-full overflow-x-hidden overflow-y-auto">
              <motion.div
                className="absolute h-[44px] w-[44px] top-[10px] right-[10px] cursor-pointer"
                variants={closeVariants}
                onClick={handleModalExit}
                whileHover="hover"
              >
                <motion.div
                  className="absolute inset-0 h-full w-full"
                  variants={closeInner}
                >
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block rotate-45 h-[2px] w-[30px] bg-lightColor"></span>
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block -rotate-45 h-[2px] w-[30px] bg-lightColor"></span>
                </motion.div>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
