import { useAdminContext } from "../../contexts/AdminContext";
import ArticlesListItem from "./ArticlesListItem";

const ArticlesList = () => {
  const { selectedSubPage, articles } = useAdminContext();

  return (
    <div className="p-3">
      {/* Title */}
      <h2 className="text-2xl uppercase font-bold pb-1">
        Manage {selectedSubPage}
      </h2>
      <div className="flex flex-col bg-white g-shadow rounded-lg">
        {/* Add New Article */}
        <ArticlesListItem />
        {/* Filtered Articles List */}
        {articles
          .filter((item) => item.type === selectedSubPage)
          .map((article) => (
            <ArticlesListItem key={article.id} article={article} />
          ))}
      </div>
    </div>
  );
};

export default ArticlesList;
