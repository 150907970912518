import { faTrashAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useAdminContext } from "../../contexts/AdminContext";

const PeopleCreatorStepItem = ({
  step,
  person,
  currentStepIndex,
  steps,
  setPersonDetails,
  setCurrentStepIndex,
  imageFile,
  setImageFile,
}) => {
  const [errors, setErrors] = useState(false);
  const { setSelectedDetailsID, selectedSubPage, uploadPerson } =
    useAdminContext();

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setErrors(false);
    setPersonDetails((current) => {
      const personInitial = { ...person };
      const newValues = {
        ...personInitial,
        [steps[currentStepIndex].name]: inputValue,
      };
      return { ...newValues };
    });
  };

  const handleImageChange = (e) => {
    setErrors(false);
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleRemoveImage = () => {
    if (imageFile) {
      setImageFile(null);
      if (document.getElementById("person-image") !== null) {
        document.getElementById("person-image").value = "";
      }
    } else {
      console.log("removing background image");
      setPersonDetails((current) => {
        const personInitial = { ...person };
        const newValues = {
          ...personInitial,
          imageLink: null,
        };
        return { ...newValues };
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      handleNextStep();
    }
  };

  const handleNextStep = () => {
    // Validate
    if (steps[currentStepIndex].required) {
      if (
        !person[steps[currentStepIndex].name] ||
        person[steps[currentStepIndex].name].trim() === ""
      ) {
        setErrors(true);
        return false;
      }
    }
    // Ensure has image if Team
    if (selectedSubPage === "team" && step.name === "image") {
      if (!imageFile && !person.imageLink) {
        setErrors("* An image is required for team members.");
        return false;
      }
    }
    if (currentStepIndex + 1 < steps.length) {
      // Increment currentStep
      setCurrentStepIndex((current) => current + 1);
    } else {
      // Present confirmation page
      initiateUpload();
    }
  };

  const handlePrevStep = () => {
    setCurrentStepIndex((current) => (current <= 0 ? 0 : current - 1));
  };

  const initiateUpload = () => {
    const thisPersonData = { ...person, type: selectedSubPage };
    const newImage = imageFile ?? null;
    uploadPerson(thisPersonData, newImage);
  };

  useEffect(() => {
    if (
      step.name === "name" ||
      step.name === "position" ||
      step.name === "bio"
    ) {
      const inputField = document.getElementById("person-" + step.name);
      if (inputField) {
        inputField.focus();
      }
    }
  }, [step.name]);

  return (
    <>
      <form
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        className="basis-[350px] grow shrink flex flex-wrap justify-center items-center content-center gap-3 py-[60px]"
      >
        <div className="basis-full flex flex-wrap gap-1">
          <h3 className="basis-full text-3xl text-black">
            {step.name === "image" &&
            (imageFile || (person && person.imageLink))
              ? "Would you like to choose a different image?"
              : step.question}
          </h3>
          {step.prompt && (
            <p className="basis-full opacity-30">{step.prompt}</p>
          )}
        </div>
        {step.name !== "image" && (
          <>
            {step.multiline ? (
              <textarea
                id={`person-${step.name}`}
                name={`person-${step.name}`}
                className={`admin-textarea ${errors ? "errors" : ""}`}
                rows={5}
                defaultValue={person ? person[step.name] : ""}
                onChange={handleInputChange}
              ></textarea>
            ) : (
              <input
                id={`person-${step.name}`}
                name={`person-${step.name}`}
                className={`admin-input capitalize ${errors ? "errors" : ""}`}
                type="text"
                defaultValue={person ? person[step.name] : ""}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
              />
            )}
          </>
        )}
        {step.name === "image" && (
          <>
            <input
              id={`person-${step.name}`}
              name={`person-${step.name}`}
              className={`basis-full capitalize`}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {(imageFile || person.imageLink) && (
              <div className="basis-full flex justify-start">
                <p
                  className="flex justify-start items-center gap-1 mt-1 cursor-pointer"
                  onClick={handleRemoveImage}
                >
                  {imageFile && (
                    <>
                      <FontAwesomeIcon
                        className="!text-blue-600"
                        icon={faUndo}
                      />
                      <span>Undo</span>
                    </>
                  )}
                  {!imageFile && person.imageLink && (
                    <>
                      <FontAwesomeIcon
                        className="!text-red-600"
                        icon={faTrashAlt}
                      />
                      <span>Remove</span>
                    </>
                  )}
                </p>
              </div>
            )}
            {errors && (
              <p className="basis-full !text-red-600 font-semibold">{errors}</p>
            )}
          </>
        )}
      </form>

      {/* Buttons */}
      <div className="w-full flex gap-4">
        {currentStepIndex === 0 && (
          <button
            className="flex-1 uppercase rounded-lg p-3 text-black font-bold cursor-pointer"
            onClick={() => setSelectedDetailsID(null)}
          >
            Cancel
          </button>
        )}
        {currentStepIndex !== 0 && (
          <button
            className="input-button !basis-0 !bg-red-600 uppercase flex-1 cursor-pointer"
            onClick={handlePrevStep}
          >
            Back
          </button>
        )}
        {currentStepIndex + 1 !== steps.length && (
          <button
            className="input-button !basis-0 uppercase flex-1 cursor-pointer"
            onClick={handleNextStep}
          >
            Next
          </button>
        )}
        {currentStepIndex + 1 >= steps.length && (
          <button
            className="input-button !basis-0 !bg-green-600 uppercase flex-1 cursor-pointer"
            onClick={handleNextStep}
          >
            Publish
          </button>
        )}
      </div>
    </>
  );
};

export default PeopleCreatorStepItem;
