import { useAdminContext } from "../contexts/AdminContext";
import { motion } from "framer-motion";
import Header from "./Header";
import Navigation from "./Navigation";
import SubPageNavigation from "./SubPageNavigation";
import People from "./people/People";
import Articles from "./articles/Articles";

const Dashboard = () => {
  const { selectedPage } = useAdminContext();
  const contentVariants = {
    hidden: { opacity: 0, y: "100vh" },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "tween", delay: 0.1, duration: 0.2 },
    },
    exit: { opacity: 0, y: 0 },
  };
  return (
    <div className="w-full flex-1 flex-col justify-start items-stretch pb-3">
      <Header />
      <Navigation />
      {/* Content */}
      {selectedPage && selectedPage.page && (
        <motion.div
          variants={contentVariants}
          initial="hidden"
          animate="visible"
        >
          {selectedPage.subPages && <SubPageNavigation />}
          {selectedPage.page === "articles" && <Articles />}
          {selectedPage.page === "people" && <People />}
          {selectedPage.page === "messages" && <h2>Messages</h2>}
        </motion.div>
      )}
      {}
    </div>
  );
};

export default Dashboard;
