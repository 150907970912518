import { useAdminContext } from "../../contexts/AdminContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const ArticlesListItem = ({ article }) => {
  const { setSelectedDetailsID, selectedSubPage } = useAdminContext();

  const handleSelectArticle = () => {
    setSelectedDetailsID((current) => (article ? article.id : "new"));
  };

  return article ? (
    <div
      className="cursor-pointer relative p-3 flex flex-wrap justify-start items-center gap-3 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0"
      onClick={handleSelectArticle}
    >
      <FontAwesomeIcon className="text-black" icon={faEdit} />
      <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
        {article.title ?? ""}
      </span>
      <FontAwesomeIcon
        className={`${
          article.visible ? "text-green-500" : "text-black opacity-40"
        }`}
        icon={article.visible ? faEye : faEyeSlash}
      />
    </div>
  ) : (
    <div
      className="cursor-pointer relative p-3 flex flex-wrap justify-start items-center gap-3 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0"
      onClick={handleSelectArticle}
    >
      <FontAwesomeIcon className="text-black" icon={faPlus} />
      <span className="flex-1">
        Add {selectedSubPage === "announcements" ? "Announcement" : "Project"}
      </span>
    </div>
  );
};

export default ArticlesListItem;
