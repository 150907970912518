import { useAdminContext } from "../../contexts/AdminContext";
import PeopleListItem from "./PeopleListItem";

const PeopleList = () => {
  const { people, selectedSubPage } = useAdminContext();

  return (
    <div className="p-3">
      {/* Title */}
      <h2 className="text-2xl uppercase font-bold pb-1">
        Manage {selectedSubPage}
      </h2>
      <div className="grid grid-cols-2 break560:grid-cols-3 break770:grid-cols-4 break960:grid-cols-5 gap-3 justify-start items-start">
        {/* Add New Person */}
        <PeopleListItem />
        {/* Filtered People List */}
        {people
          .filter((item) => item.type === selectedSubPage)
          .map((person) => (
            <PeopleListItem person={person} key={person.id} />
          ))}
      </div>
    </div>
  );
};

export default PeopleList;
