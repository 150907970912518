import { useState } from "react";
import { useAdminContext } from "../../contexts/AdminContext";
import PeopleCreatorStepItem from "./PeopleCreatorStepItem";

const PeopleCreatorSteps = ({
  person,
  setPersonDetails,
  steps,
  imageFile,
  setImageFile,
}) => {
  const { selectedDetailsID, selectedSubPage } = useAdminContext();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  return (
    <div className="basis-[300px] grow shrink flex flex-col justify-start items-stretch gap-2">
      {/* Step Title */}
      <h2 className="w-full text-center uppercase font-bold opacity-75">
        {selectedDetailsID === "new" ? "Adding " : "Updating "}
        {selectedSubPage === "team"
          ? "Team Member"
          : selectedSubPage === "artists"
          ? "Artist"
          : "Patron"}
      </h2>
      {/* Steps Progress Bar */}
      <div className="w-full flex justify-center items-stretch gap-2 h-[6px]">
        {steps.map((item, index) => (
          <div
            key={item.name}
            className={`flex-1 rounded-[3px] ${
              currentStepIndex >= index
                ? "bg-green-600"
                : "bg-[rgba(0,0,0,0.1)]"
            }`}
          ></div>
        ))}
      </div>
      {/* Step Action */}
      {steps
        .filter((item, index) => index === currentStepIndex)
        .map((item) => (
          <PeopleCreatorStepItem
            key={item.name}
            step={item}
            steps={steps}
            person={person}
            setPersonDetails={setPersonDetails}
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            imageFile={imageFile}
            setImageFile={setImageFile}
          />
        ))}
    </div>
  );
};

export default PeopleCreatorSteps;
