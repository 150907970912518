import { useAdminContext } from "../contexts/AdminContext";

const SubPageNavigation = () => {
  const {
    selectedSubPage,
    setSelectedSubPage,
    selectedPage,
    selectedDetailsID,
  } = useAdminContext();

  if (selectedDetailsID) {
    return null;
  } else {
    return (
      <div className="w-full flex justify-center items-center gap-3 pb-3 px-3">
        {selectedPage.subPages.map((subPage) => (
          <p
            key={subPage}
            onClick={() => setSelectedSubPage(subPage)}
            className={`flex-1 text-center capitalize cursor-pointer text-lg font-bold rounded-lg px-3 py-1 g-shadow ${
              selectedSubPage === subPage ? "bg-black !text-white" : "bg-white"
            }`}
          >
            {subPage}
          </p>
        ))}
      </div>
    );
  }
};

export default SubPageNavigation;
