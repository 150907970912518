import { motion } from "framer-motion";
import { useAppContext } from "../../contexts/AppContext";
import Loading from "../Loading";

const Artists = () => {
  const { pageVariants, people } = useAppContext();

  const teamVariants = {
    hidden: {},
    visible: { transition: { when: "beforeChildren", staggerChildren: 0.05 } },
  };
  const teamMemberVariants = {
    hidden: { opacity: 0, x: 400, y: -200 },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <motion.div
      className="pageContainer"
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div
        className="flex flex-wrap gap-[30px] justify-center mb-[30px]"
        variants={teamVariants}
        initial="hidden"
        animate="visible"
      >
        {people.filter((item) => item.type === "artists").length < 1 && (
          <Loading />
        )}
        {people
          .filter((item) => item.type === "artists")
          .map((item) => (
            <motion.div
              className="peopleCardFull pageSection"
              key={item.id}
              variants={teamMemberVariants}
            >
              <h3>{item.name}</h3>
              <span>{item.position}</span>
              <div>
                {item.imageLink && (
                  <div className="float-right w-[50%] max-w-[356px] ml-[15px] mb-[5px] rounded-lg overflow-hidden">
                    <img src={item.imageLink} alt={item.name} />
                  </div>
                )}
                <p
                  className="opacity-70"
                  dangerouslySetInnerHTML={{
                    __html: item.bio.replace(/(?:\r\n|\r|\n)/g, "<br />"),
                  }}
                ></p>
              </div>
            </motion.div>
          ))}
      </motion.div>
    </motion.div>
  );
};

export default Artists;
