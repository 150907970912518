import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAdminContext } from "../contexts/AdminContext";

const BackButton = () => {
  const { setSelectedDetailsID } = useAdminContext();

  // Cancel function
  const handleCancel = () => {
    setSelectedDetailsID(null);
  };

  return (
    <div className="flex justify-start items-end pb-1">
      <div
        className="flex justify-start items-center gap-3 group cursor-pointer"
        onClick={handleCancel}
      >
        <span className="group-hover:scale-125 transition-all origin-left">
          <FontAwesomeIcon className="text-black text-2xl" icon={faArrowLeft} />
        </span>
        <p className="text-2xl uppercase font-bold -translate-y-[2px]">Back</p>
      </div>
    </div>
  );
};

export default BackButton;
