import { motion } from "framer-motion";

const Quote = () => {
  const contVariants = {
    hidden: {},
    visible: { transition: { staggerChildren: 0.1 } },
  };
  const imgVariants = {
    hidden: { y: 100, scale: 0.5 },
    visible: { y: 0, scale: 1 },
  };
  const nameVariants = { hidden: { y: 100 }, visible: { y: 0 } };
  const quoteVariants = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="mt-[-15px] relative"
      variants={contVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="w-0 h-0 border-b-[40px] border-b-quoteRed border-l-[100vw] border-l-transparent relative top-px"></div>
      <div className="bg-quoteRed pt-[10px]">
        <div className="pageContainer flex justify-center items-center gap-[30px]">
          <motion.div
            className="hidden break560:block h-[100px] w-[100px] rounded-full border-2 border-white bg-[url('/images/timpigottsmith.jpeg')] bg-no-repeat bg-cover"
            variants={imgVariants}
          ></motion.div>
          <div className="flex-1">
            <motion.p
              className="!font-handwritten text-[16px] mb-[15px] break440:text-[18px] break620:text-[20px] break667:text-[22px] break710:text-[26px] break811:mb-[30px]"
              variants={quoteVariants}
            >
              "Theatre is a laboratory where we can examine our innermost
              selves. Where theatre thrives, so does democracy."
            </motion.p>
            <motion.p
              className="font-bold text-[12px] break440:text-[16px] break710:text-[20px]"
              variants={nameVariants}
            >
              Tim Pigott-Smith
            </motion.p>
            <motion.p
              className="font-fine text-[10px] break440:text-[12px] break710:text-[16px]"
              variants={nameVariants}
            >
              Patron INTENT New Theatre, (2014-2016)
            </motion.p>
          </div>
        </div>
      </div>
      <div className="w-0 h-0 border-t-[40px] border-t-quoteRed border-r-[100vw] border-r-transparent relative -top-px"></div>
    </motion.div>
  );
};

export default Quote;
