import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useAdminContext } from "../../contexts/AdminContext";
import BackButton from "../BackButton";
import PeopleCreatorPreview from "./PeopleCreatorPreview";
import PeopleCreatorSteps from "./PeopleCreatorSteps";

const PeopleCreator = () => {
  const {
    people,
    personPhotoSteps,
    personSteps,
    personUploadMessage,
    personUploadState,
    handlePersonReset,
    handlePersonFullReset,
    selectedSubPage,
    selectedDetailsID,
  } = useAdminContext();
  const [imageFile, setImageFile] = useState(null);
  const [personDetails, setPersonDetails] = useState(null);
  const [initComplete, setInitComplete] = useState(false);

  // Populate state variable if editing existing item
  useEffect(() => {
    if (personUploadState !== 0 && personUploadState !== 3) {
      // Reset errors from previous upload fail
      handlePersonReset();
    }
    // If not uploading or the upload was successful
    if (personUploadState === 0 || personUploadState === 1) {
      if (selectedDetailsID !== "new") {
        const foundPerson = people.find(
          (item) => item.id === selectedDetailsID
        );
        if (foundPerson) {
          setPersonDetails({ ...foundPerson });
        }
      } else {
        setPersonDetails({
          name: null,
          position: null,
          bio: null,
          imageLink: null,
        });
      }
    }
    setInitComplete(true);
  }, [selectedDetailsID]);

  if (initComplete && (personDetails || personUploadState !== 0)) {
    return (
      <div className="p-3">
        <BackButton />
        {personUploadState === 0 && (
          <div className="bg-white rounded-lg g-shadow p-6 flex flex-wrap gap-9 justify-center items-stretch">
            <PeopleCreatorPreview
              person={personDetails}
              imageFile={imageFile}
            />
            <PeopleCreatorSteps
              person={personDetails}
              setPersonDetails={setPersonDetails}
              steps={
                selectedSubPage === "team" || selectedSubPage === "artists"
                  ? personPhotoSteps
                  : personSteps
              }
              imageFile={imageFile}
              setImageFile={setImageFile}
            />
          </div>
        )}
        {personUploadState !== 0 && (
          <div className="bg-white rounded-lg g-shadow px-6 py-9 flex flex-col gap-9 justify-center items-center">
            {personUploadState === 1 && (
              <FontAwesomeIcon
                className="text-[60px] !text-green-600"
                icon={faCheck}
              />
            )}
            {personUploadState === 2 && (
              <FontAwesomeIcon
                className="text-[60px] !text-red-600"
                icon={faTimes}
              />
            )}
            {personUploadState === 3 && (
              <FontAwesomeIcon
                className=" text-[60px] animate-spin text-black"
                icon={faSpinner}
              />
            )}
            {personUploadMessage && (
              <p className="w-[200px] text-center">{personUploadMessage}</p>
            )}
            {(personUploadState === 1 || personUploadState === 2) && (
              <div className="flex flex-wrap justify-center items-center w-full gap-6">
                <button
                  className="basis-[150px] grow-0 shrink uppercase rounded-lg p-3 text-black font-bold cursor-pointer"
                  onClick={handlePersonFullReset}
                >
                  Back
                </button>
                {personDetails &&
                  personDetails.name &&
                  personUploadState !== 1 && (
                    <button
                      className="input-button !grow-0 !shrink !basis-[150px] uppercase"
                      onClick={handlePersonReset}
                    >
                      Check
                    </button>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default PeopleCreator;
