import { motion } from 'framer-motion';

const containerVariants =  {hidden: {},visible: {transition:{when:'beforeChildren',staggerChildren:0.15}}};
const barVariants =  {hidden: {scale:0.2},visible: { scale:1, transition: {repeat: Infinity, repeatType: 'mirror',  duration:1}}};

const Loading = () => {
    return (
        <motion.div className='flex h-[50px] w-[50px] justify-center items-center gap-[5px] my-[30px]' variants={containerVariants} initial='hidden' animate='visible'>
            <motion.div className='bg-lightColor h-full flex-1' variants={barVariants}></motion.div>
            <motion.div className='bg-lightColor h-full flex-1' variants={barVariants}></motion.div>
            <motion.div className='bg-lightColor h-full flex-1' variants={barVariants}></motion.div>
            <motion.div className='bg-lightColor h-full flex-1' variants={barVariants}></motion.div>
            <motion.div className='bg-lightColor h-full flex-1' variants={barVariants}></motion.div>
        </motion.div>
    );
}
 
export default Loading;