import { motion } from "framer-motion";
import { useAdminContext } from "../contexts/AdminContext";
import NavigationCard from "./NavigationCard";

const Navigation = () => {
  const { pageArray, selectedPage } = useAdminContext();

  return (
    <>
      {/* Main Navigation */}
      <motion.div
        layout
        className={`w-full px-3 flex flex-wrap justify-center gap-3 ${
          selectedPage ? "py-3" : "py-6"
        }`}
      >
        {pageArray.map((page) => (
          <NavigationCard key={page.page} page={page} />
        ))}
      </motion.div>
    </>
  );
};

export default Navigation;
