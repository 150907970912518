import { createContext, useState, useContext, useEffect } from "react";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../firebase-config";

export const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

const AppContextProvider = (props) => {
  // Framer Motion Variants
  const pageVariants = {
    hidden: { x: 0 },
    visible: { x: 0 },
    exit: { x: "-100vw", transition: { ease: "easeInOut" } },
  };

  // Sidebar
  const [showSidebar, setShowSidebar] = useState(false);
  const openSidebar = () => setShowSidebar(true);
  const closeSidebar = () => setShowSidebar(false);

  // Modals
  const [modal, setModal] = useState(null);
  const openModal = (type) => setModal(type);
  const closeModal = () => setModal(null);
  const closeAll = () => {
    closeModal();
    closeSidebar();
    selectTeamMember(null);
  };

  // People
  const [people, setPeople] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  useEffect(() => {
    const colRef = collection(db, "people");
    const q = query(colRef, orderBy("timestamp", "desc"));
    const unsubcriber = onSnapshot(q, (snapshot) => {
      setPeople(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    return unsubcriber;
  }, []);
  const selectTeamMember = (member) => {
    setSelectedTeamMember(member);
  };

  // Return values
  const values = {
    pageVariants,
    showSidebar,
    openSidebar,
    closeSidebar,
    modal,
    openModal,
    closeModal,
    closeAll,
    people,
    selectTeamMember,
    selectedTeamMember,
  };

  return (
    <AppContext.Provider value={values}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
