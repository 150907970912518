import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";

const Sidebar = () => {
  const { showSidebar, closeSidebar } = useAppContext();

  const backdropVariants = {
    hidden: { opacity: 1 },
    visible: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };

  const sidebarVariants = {
    hidden: { x: "100%" },
    visible: {
      x: 0,
      transition: {
        type: "tween",
        duration: ".1",
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    exit: { x: "100%" },
  };

  const linkVariants = {
    hidden: { x: "100%", opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: "100%" },
  };

  const closeVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
    hover: {},
  };

  const closeInner = {
    hover: { rotate: 90 },
  };

  const handleSidebarExit = () => closeSidebar();
  const handlePropagation = (e) => e.stopPropagation();

  return (
    <AnimatePresence exitBeforeEnter>
      {showSidebar && (
        <motion.div
          className="fixed inset-0 h-screen w-screen z-40 flex items-center justify-end bg-[rgba(0,0,0,0.7)] backdrop-blur-sm"
          key="sidebar"
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={handleSidebarExit}
        >
          <motion.div
            className="bg-backgroundGrey h-screen w-[400px] max-w-screen shadow-modal"
            onClick={handlePropagation}
            variants={sidebarVariants}
          >
            <div className="bg-[rgba(255,255,255,0.05)] h-full w-full flex items-center justify-center flex-col relative">
              <motion.div
                className="absolute h-[44px] w-[44px] top-[20px] right-[20px] cursor-pointer"
                variants={closeVariants}
                onClick={handleSidebarExit}
                whileHover="hover"
              >
                <motion.div
                  className="absolute inset-0 h-full w-full"
                  variants={closeInner}
                  whileHover="hover"
                >
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block rotate-45 h-[2px] w-[30px] bg-lightColor"></span>
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block -rotate-45 h-[2px] w-[30px] bg-lightColor"></span>
                </motion.div>
              </motion.div>
              <motion.div variants={linkVariants}>
                <Link className="sideBarLink" to="/">
                  Home
                </Link>
              </motion.div>
              <motion.div variants={linkVariants}>
                <Link className="sideBarLink" to="/about">
                  About Us
                </Link>
              </motion.div>
              <motion.div variants={linkVariants}>
                <Link className="sideBarLink" to="/artists">
                  Artists
                </Link>
              </motion.div>
              <motion.div variants={linkVariants}>
                <Link className="sideBarLink" to="/patrons">
                  Patrons
                </Link>
              </motion.div>
              <motion.div variants={linkVariants}>
                <Link className="sideBarLink" to="/projects">
                  Projects
                </Link>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
