import { motion } from "framer-motion";
import { useAppContext } from "../../contexts/AppContext";
import Loading from "../Loading";

const Team = () => {
  const { people, selectTeamMember } = useAppContext();

  const teamVariants = {
    hidden: {},
    visible: { transition: { when: "beforeChildren", staggerChildren: 0.05 } },
  };
  const teamMemberVariants = {
    hidden: { opacity: 0, x: 400, y: -200 },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <div className="pageContainer">
      <h3 className="text-center !font-handwritten !text-[40px] py-[45px] mb-[15px]">
        Management Team
      </h3>
      <motion.div
        className="flex flex-wrap gap-[30px] justify-center mb-[30px]"
        variants={teamVariants}
        initial="hidden"
        animate="visible"
      >
        {people.filter((item) => item.type === "team").length < 1 && (
          <Loading />
        )}
        {people
          .filter((item) => item.type === "team")
          .map((item) => (
            <motion.div
              className="peopleCardSmall pageSection"
              key={item.id}
              layoutId={item.id}
              onClick={() => {
                selectTeamMember(item);
              }}
              variants={teamMemberVariants}
            >
              <div
                className="peopleRoundImg"
                style={{
                  backgroundImage: "url(" + item.imageLink + ")",
                }}
              ></div>
              <h3>{item.name}</h3>
              <span>{item.position}</span>
            </motion.div>
          ))}
      </motion.div>
    </div>
  );
};

export default Team;
