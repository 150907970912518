import { useAppContext } from "../../contexts/AppContext";
import { motion, AnimatePresence } from "framer-motion";

const TeamModal = () => {
  const { selectedTeamMember, selectTeamMember } = useAppContext();

  const backdropVariants = {
    hidden: { opacity: 1 },
    visible: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };

  const teamMemberVarients = {
    hidden: { opacity: 1 },
    visible: { opacity: 1 },
  };

  const closeVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
    hover: {},
  };

  const closeInner = {
    hover: { rotate: 90 },
  };

  const bioVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 0.7, transition: { delay: 0.4 } },
    exit: { opacity: 0, transition: { delay: 0, duration: 0 } },
  };

  const handlePropagation = (e) => e.stopPropagation();

  return (
    <AnimatePresence exitBeforeEnter>
      {selectedTeamMember && (
        <motion.div
          variants={backdropVariants}
          key="teamModal"
          initial="hidden"
          animate="visible"
          exit="exit"
          className="fixed inset-0 h-full w-full z-40 flex items-center justify-center bg-[rgba(0,0,0,0.7)] backdrop-blur-sm"
          onClick={() => {
            selectTeamMember(null);
          }}
        >
          <motion.div
            className="relative bg-backgroundGrey rounded-lg max-w-[600px] max-h-[600px] h-[80%] w-[90%] overflow-hidden shadow-modal"
            layoutId={selectedTeamMember.id}
            variants={teamMemberVarients}
            initial="hidden"
            animate="visible"
            onClick={handlePropagation}
          >
            <div className="bg-[rgba(255,255,255,0.05)] pt-[44px] px-[30px] pb-[30px] h-full w-full overflow-x-hidden overflow-y-auto">
              <motion.div
                className="absolute h-[44px] w-[44px] top-[10px] right-[10px] cursor-pointer"
                variants={closeVariants}
                onClick={() => {
                  selectTeamMember(null);
                }}
                whileHover="hover"
              >
                <motion.div
                  className="absolute inset-0 h-full w-full"
                  variants={closeInner}
                >
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block rotate-45 h-[2px] w-[30px] bg-lightColor"></span>
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block -rotate-45 h-[2px] w-[30px] bg-lightColor"></span>
                </motion.div>
              </motion.div>
              <div
                className="peopleRoundImg"
                style={{
                  backgroundImage: "url(" + selectedTeamMember.imageLink + ")",
                }}
              ></div>
              <h3 className="text-center font-handwritten mb-[5px] text-[20px]">
                {selectedTeamMember.name}
              </h3>
              <span className="block text-center font-handwritten opacity-60  mb-[30px]">
                {selectedTeamMember.position}
              </span>
              <motion.p
                className=""
                variants={bioVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                dangerouslySetInnerHTML={{
                  __html: selectedTeamMember.bio.replace(
                    /(?:\r\n|\r|\n)/g,
                    "<br />"
                  ),
                }}
              ></motion.p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TeamModal;
