import { motion } from "framer-motion";

const stripesVarients = {
  hidden: { opacity: 0, rotate: "-90deg" },
  visible: { opacity: 1, rotate: "-45deg", transition: { delay: 0.3 } },
  exit: {
    opacity: 1,
    rotate: "-90deg",
    transition: { ease: "easeInOut", duration: 0.2 },
  },
};

const Stripes = () => {
  return (
    <div className="fixed top-0 right-0 h-screen w-screen -z-[1]">
      <motion.span
        className="absolute right-[30px] top-0 h-[40000px] w-3/4 break770:w-1/2 origin-top-left flex gap-[10px]"
        variants={stripesVarients}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className={`bg-quoteRed h-full basis-[10px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[60px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[30px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[4px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[90px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[20px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[60px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[3px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[90px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[30px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[4px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[60px] shrink`}></div>
        <div className={`bg-quoteRed h-full basis-[10px] shrink grow`}></div>
      </motion.span>
    </div>
  );
};

export default Stripes;
