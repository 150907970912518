import { useAdminContext } from "../../contexts/AdminContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";

const PeopleListItem = ({ person }) => {
  const { setSelectedDetailsID } = useAdminContext();

  const handleSelectPerson = () => {
    setSelectedDetailsID((current) => (person ? person.id : "new"));
  };

  return person ? (
    <div
      className="bg-white g-shadow cursor-pointer rounded-lg flex flex-col gap-3 items-center justify-center p-6"
      onClick={handleSelectPerson}
    >
      {person.imageLink && (
        <div
          className="rounded-full h-[100px] w-[100px] bg-[rgba(0,0,0,0.05)] bg-cover bg-center"
          style={{
            backgroundImage: "url(" + person.imageLink + ")",
          }}
        ></div>
      )}
      {!person.imageLink && (
        <div className="rounded-full h-[100px] w-[100px] bg-[rgba(0,0,0,0.05)] flex justify-center items-center">
          <FontAwesomeIcon className="text-black text-[30px]" icon={faUser} />
        </div>
      )}
      <p className="text-center w-full overflow-hidden whitespace-nowrap text-ellipsis">
        {person.name}
      </p>
    </div>
  ) : (
    <div
      className=" cursor-pointer rounded-lg flex flex-col gap-3 items-center justify-center p-6 group"
      onClick={handleSelectPerson}
    >
      <div className="h-[100px] w-[100px] flex justify-center items-center">
        <FontAwesomeIcon
          className="text-7xl !text-black group-hover:scale-[110%] transition-all"
          icon={faPlus}
        />
      </div>
      <p className="text-center">Add New</p>
    </div>
  );
};

export default PeopleListItem;
