import { Route, Switch, useLocation } from "react-router-dom";
import { useAppContext } from "./contexts/AppContext";
import ScrollToTop from "./helpers/ScrollToTop";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import Sidebar from "./components/Sidebar";
import Home from "./components/home/Home";
import NotFound from "./NotFound";
import About from "./components/about/About";
import TeamModal from "./components/about/TeamModal";
import Artists from "./components/artists/Artists";
import Patrons from "./components/patrons/Patrons";
import Stripes from "./components/shapes/Stripes";

function App() {
  const location = useLocation();
  const { closeAll, selectedTeamMember } = useAppContext();

  return (
    <div className="App no_select">
      <ScrollToTop />
      <AnimatePresence exitBeforeEnter>
        {location.pathname !== "/about" && <Stripes />}
      </AnimatePresence>
      <Modal />
      <Sidebar />
      <Navbar />
      <TeamModal />
      <div className="min-h-[calc(100vh-100px)] break870:min-h-[calc(100vh-140px)]">
        <AnimatePresence onExitComplete={closeAll} exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/artists">
              <Artists />
            </Route>
            <Route path="/patrons">
              <Patrons />
            </Route>
            <Route path="/projects">{/* <Artists /> */}</Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </AnimatePresence>
      </div>
      <Footer selectedTeamMember={selectedTeamMember} />
    </div>
  );
}

export default App;
