import { useEffect } from "react";
import { useAdminContext } from "../../contexts/AdminContext";

const PeopleCreatorPreview = ({ person, imageFile }) => {
  const { selectedSubPage } = useAdminContext();

  // Update preview on text change

  // Update preview on image change
  useEffect(() => {
    const imgPreview = document.getElementById("img-preview");
    if (imageFile && imgPreview) {
      // Setting new image
      if (selectedSubPage === "team") {
        // Team member - new image set
        imgPreview.style.backgroundImage =
          "url(" + URL.createObjectURL(imageFile) + ")";
      } else {
        // Artist or patron - new image set
        imgPreview.src = URL.createObjectURL(imageFile);
      }
    } else if (!imageFile && imgPreview) {
      // Resetting image to existing image if there is one
      imgPreview.style.backgroundImage =
        person && person.imageLink ? "url('" + person.imageLink + "')" : "";
    } else {
      return false;
    }
  }, [imageFile, person, selectedSubPage]);

  if (selectedSubPage === "team" && person) {
    return (
      <div className="basis-[400px] max-w-[400px] flex flex-col gap-2 justify-center items-center">
        <h2 className="w-full text-center uppercase font-bold opacity-75">
          Preview
        </h2>
        <div className="w-full rounded-lg bg-slate-100 g-shadow-inner p-3 flex justify-center items-center">
          <div className="w-full g-shadow rounded-lg basis-full  max-h-[400px] bg-white overflow-y-auto">
            <div className="min-h-[200px] w-full pt-[44px] px-[30px] pb-[30px] bg-[rgba(0,0,0,0.05)]">
              <div
                className={`peopleRoundImg relative overflow-hidden ${
                  imageFile || person.imageLink ? "blcok" : "hidden"
                }`}
                id="img-preview"
              ></div>
              <div
                className={`flex flex-col justify-center items-stretch gap-[5px]`}
              >
                {person.name && person.name.trim() !== "" && (
                  <h3 className="text-center !font-handwritten text-[20px]">
                    {person.name.trim()}
                  </h3>
                )}
                {person.position && person.position.trim() !== "" && (
                  <p className="block text-center !font-handwritten opacity-60">
                    {person.position.trim()}
                  </p>
                )}
              </div>
              {person.bio && person.bio.trim() !== "" && (
                <p
                  className="block mt-[20px] opacity-70"
                  dangerouslySetInnerHTML={
                    person.bio.trim()
                      ? {
                          __html: person.bio
                            .trim()
                            .replace(/(?:\r\n|\r|\n)/g, "<br />"),
                        }
                      : ""
                  }
                ></p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (person) {
    return (
      <div className="basis-[500px] max-w-[500px] flex flex-col gap-2 justify-center items-center">
        <h2 className="w-full text-center uppercase font-bold opacity-75">
          Preview
        </h2>
        <div className="w-full rounded-lg bg-slate-100 g-shadow-inner p-3 flex justify-center items-center">
          <div className="bg-white rounded-lg w-full basis-full max-h-[400px] g-shadow overflow-y-auto">
            <div className="min-h-[200px] w-full p-[30px] bg-[rgba(0,0,0,0.05)]">
              <div className="flex flex-col items-stretch justify-center gap-2">
                {person.name && person.name.trim() !== "" && (
                  <h3 className="!font-handwritten text-[24px]">
                    {person.name.trim()}
                  </h3>
                )}
                {person.position && person.position.trim() !== "" && (
                  <span className="w-full block !font-handwritten text-sm opacity-60">
                    {person.position || "Position Title"}
                  </span>
                )}
              </div>
              <div>
                {(person.imageLink || imageFile) && (
                  <div className="float-right w-[50%] max-w-[360px] ml-[15px] mb-[5px] rounded-lg overflow-hidden">
                    <img id="img-preview" src={person.imageLink ?? ""} alt="" />
                  </div>
                )}
                {person.bio && person.bio.trim() !== "" && (
                  <p
                    className="mt-[20px] opacity-70"
                    dangerouslySetInnerHTML={{
                      __html: person.bio
                        .trim()
                        .replace(/(?:\r\n|\r|\n)/g, "<br />"),
                    }}
                  ></p>
                )}
              </div>
              <div className=" clear-both"></div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PeopleCreatorPreview;
