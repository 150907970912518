import { useAdminContext } from "../../contexts/AdminContext";
import PeopleCreator from "./PeopleCreator";
import PeopleList from "./PeopleList";

const People = () => {
  const { selectedDetailsID } = useAdminContext();

  if (selectedDetailsID) {
    return <PeopleCreator />;
  } else {
    return <PeopleList />;
  }
};

export default People;
