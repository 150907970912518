import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";

export default function ScrollToTop() {
  const { selectedTeamMember, selectTeamMember } = useAppContext();
  const { pathname } = useLocation();

  window.addEventListener("popstate", () => {
    if (selectedTeamMember) {
      selectTeamMember(null);
    }
  });

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null;
}
