import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const { login } = useAuth();

  const [validationError, setValidationError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleLogin = async (e) => {
    e.preventDefault();
    // Stop function if already submitting
    if (loading) {
      return false;
    }
    let errors = false;
    // Initially set loginError and validationError to false
    setLoginError(false);
    setValidationError(false);
    // Validate login form
    if (passwordRef.current.value === "") {
      setValidationError(true);
      errors = true;
    }
    if (emailRef.current.value === "") {
      setValidationError(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    // Proceed to login if no errors
    setLoading(true);
    try {
      await login(emailRef.current.value, passwordRef.current.value);
    } catch (error) {
      setLoginError(true);
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-[16px]">
      <div className="rounded-lg bg-white g-shadow p-[16px] flex flex-col w-full max-w-[400px]">
        <h3 className="mb-[16px] uppercase text-[24px]">Login</h3>
        <form action="" onSubmit={handleLogin}>
          <div className="flex flex-row gap-[16px] flex-wrap pb-[16px]">
            <input
              className="login-input"
              name="username"
              type="email"
              ref={emailRef}
              placeholder="Email"
            />
            <input
              className="login-input"
              name="password"
              type="password"
              ref={passwordRef}
              placeholder="Password"
            />
            <button
              className="input-button g-shadow"
              type="submit"
              disabled={loading}
            >
              {!loading && (
                <FontAwesomeIcon
                  className="!text-white text-[20px]"
                  icon={faRightToBracket}
                />
              )}
              {loading && (
                <FontAwesomeIcon
                  className="!text-white text-[20px] animate-spin"
                  icon={faSpinner}
                />
              )}
            </button>
          </div>
          {validationError && (
            <h5 className="!text-red-600">* Validation errors</h5>
          )}
          {loginError && <h5 className="!text-red-600">* Login failed</h5>}
        </form>
      </div>
    </div>
  );
};

export default Login;
