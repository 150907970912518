import { useAdminContext } from "../../contexts/AdminContext";
import ArticleDetails from "./ArticleDetails";
import ArticlesList from "./ArticlesList";

const Articles = () => {
  const { selectedDetailsID } = useAdminContext();

  if (selectedDetailsID) {
    return <ArticleDetails />;
  } else {
    return <ArticlesList />;
  }
};

export default Articles;
