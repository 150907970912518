import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAdminContext } from "../contexts/AdminContext";
import { motion } from "framer-motion";

const NavigationCard = ({ page }) => {
  const {
    selectedPage,
    setSelectedPage,
    setSelectedSubPage,
    selectedDetailsID,
    setSelectedDetailsID,
  } = useAdminContext();

  const textVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0.1 },
    },
  };

  const handlePageSelection = () => {
    if (selectedDetailsID && page.page === selectedPage.page) {
      // Details open and clicking on current selected page - close details but remain on same subPage and page
      setSelectedDetailsID((current) => null);
    } else {
      // Reset selected details
      setSelectedDetailsID((current) => null);
      // Reset Sub Page Selection
      setSelectedSubPage((current) =>
        page.subPages ? page.subPages[0] : null
      );
      // Select Page
      setSelectedPage((current) => {
        if (!selectedPage) {
          return { ...page };
        } else {
          return selectedPage.page !== page.page ? { ...page } : null;
        }
      });
    }
  };

  return (
    <motion.div
      layout
      onClick={handlePageSelection}
      className={`grow shrink rounded-lg flex gap-3 items-center justify-center cursor-pointer overflow-hidden ${
        !selectedPage
          ? "basis-[165px] bg-white g-shadow flex-col p-6"
          : selectedPage.page === page.page
          ? "px-1 break500:px-3 py-3"
          : "bg-white g-shadow px-1 break500:px-3 py-3"
      }`}
    >
      <motion.div
        layout
        className={`relative ${
          !selectedPage
            ? "h-[100px] w-[100px]"
            : "h-[30px] w-[30px] break500:h-[50px] break500:w-[50px]"
        } rounded-full flex items-center justify-center ${page.bgColour}`}
      >
        <FontAwesomeIcon
          className={`!text-black ${
            !selectedPage ? "text-4xl" : "text-base break500:text-xl"
          }`}
          icon={page.icon}
        />
      </motion.div>
      {!selectedPage && (
        <motion.h2
          variants={textVariants}
          initial="hidden"
          animate="visible"
          className="w-full text-center capitalize"
        >
          {page.page}
        </motion.h2>
      )}
    </motion.div>
  );
};

export default NavigationCard;
