import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppContextProvider from "./contexts/AppContext";
import App from "./App";
import Admin from "./Admin";
import AuthContextProvider from "./contexts/AuthContext";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/admin">
          <AuthContextProvider>
            <Admin />
          </AuthContextProvider>
        </Route>
        <Route path={"*"}>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
