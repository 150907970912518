import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useAppContext } from "../contexts/AppContext";

const Navbar = () => {
  const containerVarients = {
    hidden: { x: 0, y: 0 },
    visible: {
      x: 0,
      y: 0,
      transition: { when: "beforeChildren", staggerChildren: 0.1 },
    },
  };
  const imageVariants = {
    hidden: { y: -200, x: -100, opacity: 0 },
    visible: { y: 0, x: 0, opacity: 1, transition: { delay: 0.1 } },
  };
  const linksVariants = {
    hidden: { y: -200, x: -20, opacity: 0 },
    visible: { y: 0, x: 0, opacity: 1 },
  };
  const sandwichVariants = {
    hidden: { y: -200, x: 100, opacity: 0 },
    visible: { y: 0, x: 0, opacity: 1 },
    hover: { scale: 1.2 },
  };
  const scaleLink = { scale: 1.3 };

  const { openSidebar, openModal } = useAppContext();
  const handleOpenSidebar = () => openSidebar();
  const handleContactClick = () => openModal("contact");

  return (
    <motion.div
      className="relative flex justify-center items-center tracking-wide h-[60px] break870:h-[100px]"
      variants={containerVarients}
      initial="hidden"
      animate="visible"
    >
      <Link
        to="/"
        className="absolute left-[30px] top-0 bg-backgroundGrey h-full py-[10px]"
      >
        <motion.img
          className="h-full"
          src="/images/logo1.png"
          alt="Intent Logo"
          variants={imageVariants}
        />
      </Link>
      <Link
        to="/about"
        className="hidden justify-center items-center break770:flex"
      >
        <motion.span
          className="px-4 py-3 relative break1000:px-8"
          variants={linksVariants}
          whileHover={scaleLink}
        >
          About
        </motion.span>
      </Link>
      <span className="navbarDot hidden break770:block"></span>
      <Link
        to="/artists"
        className="hidden justify-center items-center break770:flex"
      >
        <motion.span
          className="px-4 py-3 relative break1000:px-8"
          variants={linksVariants}
          whileHover={scaleLink}
        >
          Artists
        </motion.span>
      </Link>
      <span className="navbarDot hidden break770:block"></span>
      <Link
        to="/patrons"
        className="hidden justify-center items-center break770:flex"
      >
        <motion.span
          className="px-4 py-3 relative break1000:px-8"
          variants={linksVariants}
          whileHover={scaleLink}
        >
          Patrons
        </motion.span>
      </Link>
      <span className="navbarDot hidden break770:block"></span>
      <Link
        to="/projects"
        className="hidden justify-center items-center break770:flex"
      >
        <motion.span
          className="px-4 py-3 relative break1000:px-8"
          variants={linksVariants}
          whileHover={scaleLink}
        >
          Projects
        </motion.span>
      </Link>
      <span className="navbarDot hidden break770:block"></span>
      <button
        className="hidden justify-center items-center break770:flex"
        onClick={handleContactClick}
      >
        <motion.span
          className="px-4 py-3 relative break1000:px-8"
          variants={linksVariants}
          whileHover={scaleLink}
        >
          Contact
        </motion.span>
      </button>
      <motion.div
        className="absolute right-[30px] top-[11px] flex flex-col gap-[8px] py-2 items-end cursor-pointer break870:top-[30px]"
        variants={sandwichVariants}
        initial="hidden"
        animate="visible"
        whileHover="hover"
        onClick={handleOpenSidebar}
      >
        <span className="w-[30px] h-[2px] bg-lightColor rounded"></span>
        <span className="w-[25px] h-[2px] bg-lightColor rounded"></span>
        <span className="w-[30px] h-[2px] bg-lightColor rounded"></span>
      </motion.div>
    </motion.div>
  );
};

export default Navbar;
