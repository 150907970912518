import { motion } from "framer-motion";
import Quote from "./Quote";
import Team from "./Team";
import { useAppContext } from "../../contexts/AppContext";

const About = () => {
  const { pageVariants } = useAppContext();
  return (
    <motion.div
      variants={pageVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="about-us w-full relative h-[calc(100vh-300px)] break490:h-[calc(100vh-280px)] break560:h-[calc(100vh-300px)] break620:h-[calc(100vh-320px)] break667:h-[calc(100vh-340px)] break710:h-[calc(100vh-360px)] break870:h-[calc(100vh-375px)] break1156:h-[calc(100vh-355px)]"></div>
      <Quote />
      <Team />
    </motion.div>
  );
};

export default About;
