import { createContext, useContext, useState, useEffect } from "react";
import {
  doc,
  Timestamp,
  onSnapshot,
  addDoc,
  updateDoc,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase-config";
import {
  faNewspaper,
  faUsers,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
export const AdminContext = createContext();

export function useAdminContext() {
  return useContext(AdminContext);
}

const AdminContextProvider = (props) => {
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedSubPage, setSelectedSubPage] = useState(null);
  const [selectedDetailsID, setSelectedDetailsID] = useState(null);

  const pageArray = [
    {
      page: "articles",
      icon: faNewspaper,
      bgColour: "bg-red-300",
      subPages: ["announcements", "projects"],
    },
    {
      page: "people",
      icon: faUsers,
      bgColour: "bg-blue-300",
      subPages: ["team", "artists", "patrons"],
    },
    {
      page: "messages",
      icon: faEnvelope,
      bgColour: "bg-green-300",
      subPages: ["general", "requests"],
    },
  ];

  // People
  const [people, setPeople] = useState([]);
  const [personUploadMessage, setPersonUploadMessage] = useState(null);
  const [personUploadState, setPersonUploadState] = useState(0);

  const personPhotoSteps = [
    { name: "name", question: "What is the person's name?", required: true },
    { name: "position", question: "What is the person's role or job title?" },
    {
      name: "bio",
      question: "Question about biography?",
      multiline: true,
      required: true,
    },
    { name: "image", question: "Select a photo for this person." },
  ];

  const personSteps = [
    { name: "name", question: "What is the person's name?", required: true },
    {
      name: "position",
      question: "How is this person connected to Intent?",
      prompt: "For patrons type 'Patron', for associates type 'Associate'.",
    },
    {
      name: "bio",
      question: "Question about biography?",
      multiline: true,
      required: true,
    },
  ];

  const uploadPerson = async (personData, personImage) => {
    if (personUploadState !== 0) {
      return false;
    }
    setPersonUploadState(3);
    // Set variables
    let uploadData = { ...personData, timestamp: Timestamp.now() };
    const uploadImage = personImage;
    // Upload image and store image URL
    if (uploadImage) {
      setPersonUploadMessage("Uploading image");
      await uploadPhoto(personImage, personData.type)
        .then((imagePath) => {
          // Append or replace imageLink with new imageLink
          uploadData = { ...uploadData, imageLink: imagePath };
          setPersonUploadMessage("Image upload success");
        })
        .catch((err) => {
          // Upload failed
          setPersonUploadMessage("Image upload failed");
          setPersonUploadState(2);
          return false;
        });
    }
    setPersonUploadMessage("Uploading person's details");
    if (uploadData.id) {
      // Updating person
      const docRef = doc(db, "people", uploadData.id);
      await updateDoc(docRef, { ...uploadData })
        .then((res) => {
          // Success
          setPersonUploadMessage("Upload complete");
          setPersonUploadState(1);
        })
        .catch((err) => {
          // Fail
          setPersonUploadMessage("Details upload failed");
          setPersonUploadState(2);
        });
    } else {
      // Creating person
      await addDoc(collection(db, "people"), { ...uploadData })
        .then((res) => {
          // Success
          setPersonUploadMessage("Upload complete");
          setPersonUploadState(1);
        })
        .catch((err) => {
          // Fail
          setPersonUploadMessage("Details upload failed");
          setPersonUploadState(2);
        });
    }
  };

  const uploadPhoto = async (photo, type) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(
        storage,
        "/" +
          type +
          "/" +
          Date.now() +
          photo.name.toLowerCase().replace(" ", "_")
      );
      const uploadTask = uploadBytesResumable(storageRef, photo);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handlePersonReset = () => {
    setPersonUploadState(0);
    setPersonUploadMessage(null);
  };

  const handlePersonFullReset = () => {
    setPersonUploadState(0);
    setPersonUploadMessage(null);
    setSelectedDetailsID(null);
  };

  useEffect(() => {
    const colRef = collection(db, "people");
    const q = query(colRef, orderBy("timestamp", "desc"));
    const unsubcriber = onSnapshot(q, (snapshot) => {
      console.log("People received: ", snapshot);
      setPeople(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    return unsubcriber;
  }, []);

  // Articles
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    const colRef = collection(db, "articles");
    const q = query(colRef);
    const unsubcriber = onSnapshot(q, (snapshot) => {
      setArticles(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    return unsubcriber;
  }, []);

  const values = {
    // Admin Areas
    selectedPage,
    setSelectedPage,
    selectedSubPage,
    setSelectedSubPage,
    selectedDetailsID,
    setSelectedDetailsID,
    pageArray,
    // People
    people,
    personPhotoSteps,
    personSteps,
    uploadPerson,
    personUploadMessage,
    personUploadState,
    handlePersonReset,
    handlePersonFullReset,
    // Articles
    articles,
  };

  return (
    <AdminContext.Provider value={values}>
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
